.cropper {
  border-radius: 20px;
}

.flex {
  display: flex;
  width: auto;
}

.alertStyle {
  width: max-content;
  display: flex;
  /* transition: all 1s ease-in-out; */
}
