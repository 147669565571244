* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  /* font-family: "Mulish", sans-serif; */
}

:root {
  --border-color: #444450;
  --red: #eb2727;
  --yellow: #ffb100;

  /* light mode colors */
  /* --l-primary: #2b87bf; 0b1e49*/
  --primary: #ea3148;
  --d-blue: #0b1e49;
  --white: #fbfbfb;
  --black: #0c0c0f;
  --d-yellow: #ff9900;
  --l-gray: #cacaca;
  --gray: #a5a5a5;
  /* --gray: #5b5b5b; */
  --d-gray: #1a1c1e;
  --l-blue: #2b87bf;
  --placeholder-color: #9b9b9b;
  --input-bg: #292d50;
  --icon-size: 24px;
  /* dark mode colors */
  --dropdown-gray: #636363;
  --input-outline: #bebebe;

  --linear-background--light: linear-gradient(
      -60deg,
      rgba(0, 0, 0, 0) 50%,
      rgb(5, 16, 48) 95%
    ),
    linear-gradient(-90deg, rgba(0, 0, 0, 0) 5%, transparent 30%);
  z-index: 2;

  --linear-background--dark: linear-gradient(
      -75deg,
      rgba(0, 0, 0, 0) 40%,
      rgb(9, 8, 10) 95%
    ),
    linear-gradient(10deg, rgba(9, 8, 10, 0.5) 5%, transparent 30%);
  z-index: 2;

  --linear-background--light--user: linear-gradient(
      -170deg,
      rgba(0, 0, 0, 0) 65%,
      rgb(5, 16, 48) 90%
    ),
    linear-gradient(10deg, rgba(0, 0, 0, 0.2) 5%, transparent 30%);
  z-index: 2;

  --linear-background--dark--user: linear-gradient(
      -165deg,
      rgba(0, 0, 0, 0) 65%,
      rgb(9, 8, 10) 90%
    ),
    linear-gradient(10deg, rgba(9, 8, 10, 0.2) 5%, transparent 30%);
  z-index: 2;

  /* 650px media screen backgrounds */
  --linear-background--dark--650: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 30%,
      rgb(9, 8, 10) 80%
    ),
    linear-gradient(10deg, rgba(9, 8, 10, 0.5) 5%, transparent 30%);
  z-index: 2;

  /* More details page bacground color */
  --linear-background--light--md: linear-gradient(
    0deg,
    rgb(248, 248, 248) 3%,
    rgba(194, 194, 194, 0.01) 98%
  );

  --linear-background--dark--md: linear-gradient(
    0deg,
    rgb(28, 28, 36) 5%,
    rgba(59, 59, 59, 0.4) 98%
  );

  /* Media prev container overlay */
  --linear-background-media-overlay: linear-gradient(
    0deg,
    rgb(12, 12, 15) 8%,
    rgba(0, 0, 0, 0) 80%
  );

  /* top talent overlay */
  --linear-background-top-talent-overlay: linear-gradient(
    0deg,
    rgb(12, 12, 15) 5%,
    rgba(0, 0, 0, 0.1) 50%
  );
}

body {
  font-size: 14px;
}

h1 {
  font-size: 28px;
  font-weight: 600;
}

h2 {
  font-size: 22px;
  font-weight: 600;
}

h3 {
  font-size: 16px;
  font-weight: 600;
}

h4 {
  font-size: 15px;
  font-weight: 500;
}

p {
  font-size: 14px;
}
