.social-swiper{
    width: 3.5rem; 
}

.swiper-slide-top-post{
    border: 1px solid red;
    width: min-content;
    width: 1rem;
}

.swiper-wrap-top-post{
    width: 100%;
    margin: 0;
    padding: 0;
    border: 1px solid greenyellow;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: greenyellow;
    gap: 1rem;
    overflow: hidden;
}